<template>
  <div style="height: 100%;background: #F5F5F5;">
    <div class="biao_ti">
      <van-icon @click="back" class="fanhui" name="arrow-left" />
      <span>待领取</span>
    </div>
    <div style="padding-top:46px;position: relative;background: #F5F5F5;">
      <div class="shanpin_lis">
        <div class="list_piao" v-for="(item,index) in list" :key="index">
          <div v-if="item.getType == 2" class="daoji">
            <div class="qiang">
              <span style="margin-left: 3px;">抢</span>
            </div>

            <van-count-down class="right shijianf" :time="item.time">
              <template #default="timeData">
                <span>还剩：</span>
                <span class="jian" v-if="item.time > 86400000">{{ timeData.days }}</span>
                <span class="colon" v-if="item.time > 86400000">:</span>
                <span class="jian">{{ timeData.hours }}</span>
                <span class="colon">:</span>
                <span class="jian">{{ timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="jian">{{ timeData.seconds }}</span>
              </template>
            </van-count-down>
          </div>

          <div class="left c_tup">
            <div class="cjiage">
              <span v-if="item.type == 1">
                <span class="dan">￥</span>
                <span>{{item.discountPrice}}</span>
              </span>
              <span v-if="item.type == 2">{{item.discountPrice}}折</span>
              <span v-if="item.type == 3">免费券</span>
              <span v-if="item.type == 4">免费券</span>
            </div>
            <div class="sm">
              <span v-if="item.type == 1">满{{item.useCill}}元可用</span>
              <!-- <span v-if="item.type == 2">{{item.useCill}}可用</span>
              <span v-if="item.type == 3">{{item.useCill}}可用</span> -->
            </div>
            <img class="shangping" src="../../assets/img/linqu.png" alt />
          </div>
          <div class="left c_h1">
            <div class="shuoming">
              <span class="xianshang">{{item.useScope == 1?'线上':'线下'}}</span>
              <span class="shiyong">{{item.couponName}}</span>
              <!-- <span class="shiyong" v-if="item.type == 2">折扣券</span>
              <span class="shiyong" v-if="item.type == 3">免费券</span>-->
            </div>
            <div class="shijian">
              <div
                class="left c_shij"
              >{{item.startDate.substring(0,11)}}-{{item.validityDate.substring(0,11)}}</div>
              <div
                class="right linqu"
                v-if="item.isGet == 0 "
                @click="linqu(item)"
                v-preventReClick
              >点击领取</div>
              <div class="right linqub" v-if="item.isGet == 1" v-preventReClick @click="totiaozhuan">立即使用</div>
            </div>
          </div>
          <img class="yilin" v-if="item.isGet == 1" src="../../assets/img/yilinqu.png" alt />
        </div>
      </div>
      <van-empty v-if="list.length < 1" description="暂无数据~" />
    </div>
  </div>
</template>

<script>
import orderModel from "../../api/order";
import preventReClick from "../../assets/preventReClick";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  // mixins: [preventBack], //注入
  components: {},
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      forlist: [
        { name: "全部", nuber: "0" },
        { name: "洗车", nuber: "0" },
        { name: "打蜡", nuber: "0" },
        { name: "内饰洗车", nuber: "0" },
        { name: "杀菌消毒", nuber: "0" },
        { name: "补胎", nuber: "0" }
      ],
      list: [],
      time: 0,
      checked: false,
      show: true,

      isActive: 1
    };
  },
  methods: {
    dianji() {
      this.checked = !this.checked;
    },
    linqu(item) {
      if (item.getType == 1) {
        const data = {
          id: item.id
        };
        orderModel
          .receive(data)
          .then(e => {
            if (item.getType == 1) {
              this.$toast("已存入券包");
            }
            setTimeout(() => {
              this.onLoad();
            }, 800);
          })
          .catch(() => loading.clear());
      } else {
        const data = {
          id: item.id,
          carNo: this.chexin.carNo,
          customerId: this.userInfo.customerId,
          name: this.userInfo.realName,
          phone: this.userInfo.phone
        };
        orderModel
          .limit(data)
          .then(e => {
            if (item.getType == 2) {
              this.$toast("恭喜！已抢到，存入券包。");
            }
            setTimeout(() => {
              this.onLoad();
            }, 800);
          })
          .catch(() => loading.clear());
      }
    },
    onLoad() {
      const data = {
        gid: this.shangjia.id,
        customerId: this.userInfo.customerId
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      orderModel
        .myUnCoupon(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
          let date = new Date().getTime();
          this.list.map((c, i) => {
            if (c.getType == 2) {
              var time1 = c.validityDate.replace(/-/g, "/");
              var date2 = new Date(time1).getTime();
              var staytimeGap = date2 - date;
              this.$set(c, "time", staytimeGap);
            }
          });
          console.log(this.list);
        })
        .catch(() => loading.clear());
    },
      totiaozhuan() {
      this.$router.push({
        path: "user"
        // query: {}
      });
    },
    pushHistory() {
      //写入空白历史路径
      let state = {
        title: "title",
        url: "#"
      };
      window.history.pushState(state, state.title, state.url);
    },

    backziding() {
      var _this = this;
      this.pushHistory();
      window.addEventListener(
        "popstate",
        function(e) {
          location.href = "/user";
        },
        false
      );
    },
    back() {
      //
      if (sessionStorage.getItem("to") != null) {
        this.$router.push({
          path: "user"
        });
      } else {
        this.$router.go(-1);
      }
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {
    if (sessionStorage.getItem("to") != null) {
      this.backziding();
    }
  }
};
</script>
<style >
.maintain .van-ellipsis {
  color: #fff !important;
}
.maintain .van-icon {
  color: #fff !important;
}
.maintain > .van-hairline--bottom::after {
  border: none !important;
}
</style>
<style lang="less" scoped>
body {
  box-sizing: border-box;
}
.xianshang {
  display: inline-block;
  color: #fff;
  background: linear-gradient(to right, #4169e1, #6495ed);
  border-radius: 5px;
  font-size: 12px;
  padding: 2px 12px;
  line-height: 15px;
  transform: scale(0.83);
}
.shijianf {
  font-size: 12px;
  color: #fff;
  transform: scale(0.83);
}
.qiang {
  float: left;
  background: red;
  width: 25px;
  height: 28px;
  color: #ffff00;
  margin-left: 1px;
  //   text-align: center;
  font-size: 13px;
  font-weight: bold;
  border-radius: 0 0px 30px 0px;
}
.daoji {
  width: 135px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
.shuoming {
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
}
.shiyong {
  margin-left: 8px;
}
// 洗车
.list_piao {
  overflow: hidden;
  margin: 5px 0;
  font-size: 14px;

  background: #fff;
  position: relative;
}
.mianfeiq {
  margin-top: 10px;
  display: block;
}
.c_h1 {
  padding: 8px 0px 0px 10px;
  width: 65%;
}
.c_shij {
  transform: scale(0.9);
}
.linqu {
  background: linear-gradient(to right, #6495ed, #4169e1);
  border-radius: 15px;
  color: #fff;
  padding: 0 12px;
}
.linqub {
  color: #6495ed;
  border: 1px solid #4169e1;
  border-radius: 15px;
  padding: 0 12px;
}
.shijian {
  margin-top: 25px;
  font-size: 12px;
}
.yilin {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 50px;
}
.shou_s {
  color: #666;
}
.c_tup {
  overflow: hidden;
  position: relative;
}

.quan_img {
  height: 15px;
  width: 22px;
  display: block;
  margin: auto;
}
.dai_a {
  font-size: 12px;
  line-height: 16px;
  transform: scale(0.8);
  color: #f1f1f1;
}
.dai {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 12px;
  margin-right: 20px;
}
.sm {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
  margin-top: 60px;
  color: #fff;
  font-size: 10px;
  transform: scale(0.83);
}
.dan {
  font-size: 16px;
}
.cjiage {
  color: #fff;
  font-weight: bold;
  font-size: 26px;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 20px;
}

.shangping {
  width: 130px;
  height: 100px;
  display: block;
}
// 底部
.bott {
  height: 55px;
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  padding: 0 12px;
}
.zaixian {
  font-size: 12px;
  line-height: 12px;
}
.bott_a {
  text-align: center;
  margin-top: 5px;
}
.bott_c {
  margin-top: 10px;
  font-size: 14px;
  background: #df3447;
  color: #fff;
  font-weight: bold;
  padding: 7px 18px;
  border-radius: 19px;
}
.heji {
  font-weight: bold;
}
.danwei {
  font-size: 10px;
  color: #d9314c;
}
.monede {
  font-size: 18px;
  font-weight: bold;
  color: #d9314c;
}
.zhe {
  color: #666;
  margin-left: 5px;
}
.bott_b {
  font-size: 13px;
  margin-right: 10px;
  margin-top: 5px;
}

.anzhuan {
  font-size: 12px;
  color: #666;
  line-height: 12px;
  text-align: right;
}
.kefu {
  width: 25px;
  height: 25px;
}
// 底部
// 标题
.biao_ti {
  height: 46px;
  background: #fff;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  line-height: 46px;
  text-align: center;
  color: #000;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.fanhui {
  line-height: 46px;
  padding: 0 10px;
  position: absolute;
  left: 0;
  top: 0;
  color: #666;
}
//标题

.van-nav-bar {
  background: #df3447;
  color: #fff;
}
.shanpin_lis {
  position: relative;
  // background: #fff;

  overflow: hidden;
}
.shanb_biao {
  border-radius: 5px;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 0;
  margin-bottom: 10px;
  background: #fff;
  //   transition: all .8s
}

.shanb_h1 {
  overflow: hidden;
  line-height: 20px;
}
.jinji {
  font-size: 9px;
  color: #fff;
  display: inline-block;
  margin-left: 34px;
  background: #d9314c;
  padding: 0 3px;
  border-radius: 3px;
  line-height: 12px;
}
.shanb_h2 {
  font-size: 12px;
  color: #666;
  margin-left: 34px;
  line-height: 16px;
}
.chek {
  font-weight: bold;
  margin-left: 10px;
}
.jiner {
  color: #666;
  margin-right: 10px;
}
.list_fuwu {
  overflow: hidden;
  position: relative;
}
.mian_a {
  width: 70px;
  margin-left: 40px;
}
.order_img {
  height: 70px;
  width: 70px;
}
.mian_b {
  font-size: 14px;
  margin-left: 15px;
  width: 215px;
}
.lis_ha {
  font-size: 12px;
  line-height: 20px;
}
.lis_hb {
  font-size: 10px;
  line-height: 12px;
  margin-top: 6px;
}
.lis_hc {
  margin-top: 5px;
}
.lis_hb span {
  display: inline-block;
  color: #9370db;
  border: 0.5px solid #9370db;
  line-height: 12px;
  margin-left: 5px;
  border-radius: 3px;
  padding: 0 2px;
}
.jiner_c {
  color: #d9314c;
}
.clear {
  clear: both;
  height: 55px;
}
.isActive {
  border: 0.5px solid #a50e43;
  color: #a50e43;
  background: #f1dadc;
}
.list_piao:nth-of-type(n + 2) {
  margin-top: 10px;
}
</style>